@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply m-0 p-0 font-kr text-base text-gray-600;
}

input,
textarea,
button,
select,
a {
  /* Prevents highlight on mobile button touch*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}

.scroll-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  /* Firefox */
  -moz-appearance: textfield;
}

a {
  color: inherit;
  text-decoration: none;
}

.thin svg path {
  stroke-width: 1;
}

.linkify a {
  color: #2170ff;
}

.linkify a:hover {
  text-decoration: underline;
}

.content-image-shadow {
  background: linear-gradient(
    180deg,
    rgba(77, 85, 98, 0) 0%,
    rgba(0, 0, 0, 0.36) 100%
  );
}

.toastui-editor-contents {
  font-family: 'Nanum Gothic', sans-serif !important;
  font-size: 14px !important;
}

* {
  box-sizing: border-box;
}

@layer components {
  /* Main */
  .btn-container {
    @apply flex shrink-0 rounded-lg px-5 py-3;
  }
  .btn-animation {
    @apply hover:opacity-70 active:bg-swygBlue-600 active:opacity-90;
  }
  .main-button-container {
    @apply rounded-base bg-swygBlue-500 px-4 py-[9px] text-sm font-semibold leading-[14px] text-white transition hover:bg-swygBlueButton-hover active:bg-swygBlueButton-pressed disabled:bg-swygBlueButton-disabled;
  }
  .image-container {
    @apply relative overflow-hidden;
  }
  .profile-image-container {
    @apply relative flex-shrink-0 overflow-hidden rounded-full bg-gray-100;
  }
  .text-input-container {
    @apply flex w-full items-center rounded border border-gray-300 bg-white p-3 focus-within:border-swygBlue-300;
  }
  .edit-container {
    @apply flex w-full items-center rounded-lg border border-gray-100 bg-gray-200 bg-opacity-50 p-3 ring-swygBlue-200 transition-colors duration-300 focus-within:border-opacity-0 focus-within:bg-white focus-within:ring-2 hover:bg-white hover:ring-2;
  }
  .project-input-container {
    @apply flex w-full items-center rounded-lg border border-gray-100 bg-gray-200 bg-opacity-50 p-3 ring-swygBlue-200 transition-colors duration-300 focus-within:border-swygBlue-200 focus-within:border-opacity-0 focus-within:bg-white focus-within:ring-2 hover:bg-white hover:ring-2 md:rounded md:border-gray-300 md:bg-white md:bg-opacity-100 md:transition-none md:focus-within:border-opacity-100 md:focus-within:ring-0 md:hover:ring-0;
  }
  .project-confirm-title-text {
    @apply flex space-x-1 text-sm font-semibold md:text-base;
  }
  .project-confirm-section-line {
    @apply mb-10 border-b;
  }
  .input-error-text {
    @apply pl-1 text-xs leading-8 text-rose-500 md:text-xs md:leading-10;
  }
  .max-length-text {
    @apply px-1 text-right text-xs text-gray-500;
  }
  .bg-modal {
    @apply bg-gray-800 bg-opacity-25;
  }
  .bg-shadow {
    @apply bg-gray-200 bg-opacity-40;
  }
  .tag-container {
    @apply rounded-md border bg-white px-4 py-1 font-bold;
  }
  .border-invisible {
    @apply border-gray-200/0;
  }
  .skeleton-text-container {
    @apply h-5 rounded-[30px] bg-gray-200;
  }
  .content-container {
    @apply -mx-3 -mt-3 -mb-5 -translate-y-2 transform rounded-2xl px-3 pt-3 pb-5 shadow-contentContainer transition-transform;
  }
  .content-detail-container {
    @apply flex flex-col items-center justify-center rounded-[11px] p-9 shadow-contentDetailContainer;
  }
  .back-button {
    @apply absolute hidden h-10 w-10 items-center justify-center rounded-full text-gray-500 hover:bg-gray-200 md:flex;
  }
  .tooltip {
    @apply rounded-sm bg-gray-200 py-[2px] px-1 text-gray-600;
  }

  /* PaaS */
  .statistical-data-tooltip {
    @apply rounded-base bg-gray-200 p-2 text-xs leading-5 text-gray-600;
  }
  .table-title {
    @apply flex h-fit justify-end space-x-[6px] px-2 py-5;
  }
}
